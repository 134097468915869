import { Injectable } from "@angular/core";
import { Resolve } from "@angular/router";
import { Observable, Subscriber } from "rxjs";

import { UsersService } from "../users.service";
import { User } from "src/app/models/shared";

@Injectable({
    providedIn: "root"
})
export class UserListResolverService implements Resolve<User[]> {
    constructor(private tps: UsersService) {}

    resolve(): Observable<User[]> | Observable<never> {
        return Observable.create((observe: Subscriber<User[]>) => {
            this.tps.refreshUsers().subscribe((users: User[]) => {
                observe.next(users);
                observe.complete();
            });
        });
    }
}
