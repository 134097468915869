import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

// Modules
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { FontAwesome } from "../../../modules/font-awesome/font-awesome.module";
import { SharedModule } from "../../../modules/shared/shared.module";
import { RolesTableModule } from "../roles/roles-table/roles-table.module";

// Forms
import { FormsModule } from "@angular/forms";
import { NgSelectModule } from "@ng-select/ng-select";

// Components
import { UserListComponent } from "./user-list/user-list.component";
import { UserComponent } from "./user/user.component";
import { UserFormComponent } from "./user-form/user-form.component";

// Routes
import { UsersRouting } from "./users.routing";
import { MatTooltipModule } from "@angular/material/tooltip";
import { TourMatMenuModule } from "ngx-ui-tour-md-menu";

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        NgSelectModule,
        NgbModule,
        FontAwesome,
        SharedModule,
        UsersRouting,
        RolesTableModule,
        MatTooltipModule,
        TourMatMenuModule
    ],
    declarations: [UserListComponent, UserComponent, UserFormComponent]
})
export class UsersModule {}
