<content-loading-animation *ngIf="loading"></content-loading-animation>

<form id="form" #form="ngForm" (ngSubmit)="form.valid && onSubmit()" *ngIf="!loading" [mustMatch]="['newPassword', 'confirmPassword']" autocomplete="off">
    <div class="title-bar">
        <div class="row justify-content-center">
            <div [class]="constants.FORM_COL_CLASS_BREAKPOINTS">
                <div class="title">
                    <h1 *ngIf="!isEdit">{{ "CREATE_NEW_USER" | translate }}</h1>
                    <h1 *ngIf="isEdit">{{ "EDIT_USER" | translate }}</h1>
                </div>
            </div>
        </div>
    </div>
    <div class="content-area">
        <div class="container-fluid">
            <div class="row justify-content-center">
                <div [class]="constants.FORM_COL_CLASS_BREAKPOINTS">
                    <div class="form-group">
                        <!-- Name -->
                        <label for="name" [ngClass]="{ 'is-invalid': form.submitted && name.errors }"
                            >{{ "NAME" | translate }}<fa-icon *ngIf="!isEdit || authType !== 'sso'" icon="asterisk" size="xs"></fa-icon
                        ></label>
                        <input
                            autocomplete="off"
                            type="text"
                            id="name"
                            name="name"
                            placeholder="{{ 'NAME' | translate }}"
                            [ngClass]="{
                                'is-invalid': form.submitted && name.errors,
                                'form-control-plaintext': isEdit && authType === 'sso',
                                'form-control': !isEdit || authType !== 'sso'
                            }"
                            [disabled]="isEdit && authType === 'sso'"
                            [(ngModel)]="user.name"
                            required
                            minlength="{{ minLength }}"
                            duplicateName="{{ userNames }}"
                            pattern="{{ constants.validators.name }}"
                            #name="ngModel"
                        />
                        <div *ngIf="name.invalid" class="invalid-feedback">
                            <div *ngIf="name.errors.required">{{ "NAME" | translate }} {{ "IS_REQUIRED" | translate }}.</div>
                            <div *ngIf="name.errors.minlength">
                                {{ "NAME" | translate }} {{ "MUST_BE_AT_LEAST" | translate }} {{ minLength }} {{ "CHARACTERS_LONG" | translate }}.
                            </div>
                            <div *ngIf="name.errors.duplicateName">{{ "NAME" | translate }} {{ "MUST_BE_UNIQUE" | translate }}.</div>
                            <div *ngIf="name.errors.pattern">
                                {{ "NAME" | translate }} {{ "CAN_NOT_CONTAIN_ANY_OF_THE_FOLLOWING_CHARACTERS:" | translate }} ' " `
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <!-- Email -->
                        <label for="email" [ngClass]="{ 'is-invalid': form.submitted && email.errors }"
                            >{{ "EMAIL" | translate }}<fa-icon *ngIf="!isEdit || authType !== 'sso'" icon="asterisk" size="xs"></fa-icon
                        ></label>
                        <input
                            autocomplete="off"
                            type="email"
                            id="email"
                            name="email"
                            placeholder="{{ 'EMAIL' | translate }}"
                            class="form-control"
                            [ngClass]="{
                                'is-invalid': form.submitted && email.errors,
                                'form-control-plaintext': isEdit && authType === 'sso',
                                'form-control': !isEdit || authType !== 'sso'
                            }"
                            [(ngModel)]="user.email"
                            [disabled]="isEdit && authType === 'sso'"
                            required
                            minlength="{{ minLength }}"
                            #email="ngModel"
                        />
                        <div *ngIf="email.invalid" class="invalid-feedback">
                            <div *ngIf="email.errors.required">{{ "EMAIL" | translate }} {{ "IS_REQUIRED" | translate }}.</div>
                            <div *ngIf="email.errors.minlength">
                                {{ "EMAIL" | translate }} {{ "MUST_BE_AT_LEAST" | translate }} {{ minLength }} {{ "CHARACTERS_LONG" | translate }}.
                            </div>
                        </div>
                    </div>
                    <fieldset class="form-group" *ngIf="!isEdit">
                        <legend for="type">{{ "AUTHENTICATION" | translate }} {{ "TYPE" | translate }}</legend>
                        <mat-button-toggle-group name="type" aria-label="auth Type" [(ngModel)]="authType">
                            <mat-button-toggle value="sso">{{ "SSO" | translate }}</mat-button-toggle>
                            <mat-button-toggle value="zm">{{ "ZEN_MASTER" | translate }}</mat-button-toggle>
                        </mat-button-toggle-group>
                    </fieldset>
                    <div class="form-group" *ngIf="authType === 'zm'">
                        <!-- New Password -->
                        <label for="newPassword" [ngClass]="{ 'is-invalid': form.submitted && newPassword.errors }">
                            {{ "NEW" | translate }} {{ "PASSWORD" | translate }}
                            <fa-icon *ngIf="!isEdit" icon="asterisk" size="xs"></fa-icon>
                            <app-password-strength-icon [password]="user.password"></app-password-strength-icon>
                        </label>
                        <input
                            autocomplete="cc-csc"
                            type="password"
                            id="newPassword"
                            name="newPassword"
                            placeholder="{{ 'NEW' | translate }} {{ 'PASSWORD' | translate }}"
                            class="form-control"
                            [ngClass]="{ 'is-invalid': form.submitted && newPassword.errors }"
                            [(ngModel)]="user.password"
                            [required]="!isEdit"
                            pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*]).{8,}$"
                            #newPassword="ngModel"
                        />
                        <small *ngIf="isEdit" translate>BLANK_SECRET_EDIT</small>
                        <div *ngIf="newPassword.invalid" class="invalid-feedback">
                            <div *ngIf="newPassword.errors.required">{{ "NEW" | translate }} {{ "PASSWORD" | translate }} {{ "IS_REQUIRED" | translate }}.</div>
                            <div *ngIf="newPassword.errors.pattern">{{ "PASSWORD_MUST_BE" | translate }}.</div>
                        </div>
                    </div>
                    <div class="form-group" *ngIf="authType === 'zm'">
                        <!-- Confirm Password -->
                        <label for="confirmPassword" [ngClass]="{ 'is-invalid': form.submitted && confirmPassword.errors }">
                            {{ "CONFIRM" | translate }} {{ "NEW" | translate }} {{ "PASSWORD" | translate }}
                            <fa-icon *ngIf="!isEdit" icon="asterisk" size="xs"></fa-icon>
                        </label>
                        <input
                            autocomplete="cc-csc"
                            type="password"
                            id="confirmPassword"
                            name="confirmPassword"
                            placeholder="{{ 'CONFIRM' | translate }} {{ 'NEW' | translate }} {{ 'PASSWORD' | translate }}"
                            class="form-control"
                            [ngClass]="{ 'is-invalid': form.submitted && confirmPassword.errors }"
                            [(ngModel)]="user.confirmPassword"
                            [required]="!isEdit"
                            #confirmPassword="ngModel"
                        />
                        <div *ngIf="confirmPassword.invalid" class="invalid-feedback">
                            <div *ngIf="confirmPassword.errors.required">
                                {{ "CONFIRM" | translate }} {{ "NEW" | translate }} {{ "PASSWORD" | translate }} {{ "IS_REQUIRED" | translate }}.
                            </div>
                            <div *ngIf="confirmPassword.errors.mustMatch">{{ "PASSWORDS_MUST_MATCH" | translate }}.</div>
                        </div>
                    </div>
                    <div class="form-group" *ngIf="authType === 'zm'">
                        <!-- Force password reset -->
                        <div class="form-check">
                            <input type="checkbox" class="form-check-input" id="must_reset" name="must_reset" [(ngModel)]="user.must_reset" />
                            <label class="form-check-label" for="must_reset">{{ "MUST_RESET_PASSWORD" | translate }} </label>
                        </div>
                    </div>
                    <div class="form-group">
                        <!-- Administrator -->
                        <div class="form-check">
                            <input type="checkbox" class="form-check-input" id="is_admin" name="is_admin" [(ngModel)]="user.is_admin" />
                            <label class="form-check-label" for="is_admin">{{ "ADMINISTRATOR" | translate }} </label>
                        </div>
                    </div>
                    <div class="form-group">
                        <!-- Enabled -->
                        <div class="form-check">
                            <input type="checkbox" class="form-check-input" id="is_enabled" name="is_enabled" [(ngModel)]="user.is_enabled" />
                            <label class="form-check-label" for="is_enabled">{{ "ENABLED" | translate }} </label>
                        </div>
                    </div>
                    <div class="form-group">
                        <!-- Is Incident Manager -->
                        <div class="form-check">
                            <input
                                type="checkbox"
                                class="form-check-input"
                                id="is_incident_manager"
                                name="is_incident_manager"
                                [(ngModel)]="user.is_incident_manager"
                            />
                            <label class="form-check-label" for="is_incident_manager">{{ "INCIDENT_MANAGER" | translate }} </label>
                        </div>
                    </div>
                    <div class="form-group">
                        <!-- Groups -->
                        <label for="groupsSelect">
                            {{ "GROUPS" | translate }}
                        </label>
                        <zx-groups-select name="groupsSelect" [(model)]="user.groups"></zx-groups-select>
                    </div>
                    <div class="form-group mb-0">
                        <!-- Roles -->
                        <label for="rolesSelect">
                            {{ "ROLES" | translate }}
                        </label>
                        <zx-roles-select name="rolesSelect" [(model)]="user.roles"></zx-roles-select>
                    </div>
                    <app-error [marginBottom]="false" [marginTop]="true"></app-error>
                    <hr />
                    <div class="text-end">
                        <button type="button" class="btn btn-outline-secondary me-2" (click)="cancel()" [disabled]="saving">
                            <fa-icon icon="times"></fa-icon>
                            {{ "CANCEL" | translate }}
                        </button>
                        <button
                            zmid="form-submit"
                            type="submit"
                            class="btn btn-primary"
                            [disabled]="saving"
                            [ngClass]="{ 'btn-danger': form.submitted && form.invalid }"
                        >
                            <fa-icon icon="check" size="sm"></fa-icon>
                            {{ "SAVE" | translate }}<span *ngIf="saving" class="spinner-border spinner-border-sm ms-1" role="status" aria-hidden="true"></span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>
