import { Component, OnInit, OnDestroy } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Subscription } from "rxjs";

import { Constants } from "../../../../constants/constants";
import { UsersService } from "../users.service";
import { User } from "src/app/models/shared";
import { TitleCasePipe } from "@angular/common";
import { TranslateService } from "@ngx-translate/core";
import { TitleService } from "../../../../services/title.service";

@Component({
    selector: "app-user-form",
    templateUrl: "./user-form.component.html",
    providers: [TitleCasePipe]
})
export class UserFormComponent implements OnInit, OnDestroy {
    user = new User();
    userId: number;
    userNames: string[];
    action: string;

    submitted = false;
    minLength = 2;
    isEdit = false;

    loading = true;
    saving = false;

    authType = "zm";
    constants = Constants;

    private usersSubscription: Subscription;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private usersService: UsersService,
        private translate: TranslateService,
        private titleService: TitleService,
        private titlecasePipe: TitleCasePipe
    ) {
        // The ActivatedRoute dies with the routed component and so the subscription dies with it.
        this.route.paramMap.subscribe(params => {
            this.userId = parseInt(params.get("id"), 10);
            this.action = params.get("action");
            if (this.userId) {
                this.isEdit = true;

                this.usersService.refreshUser(this.userId, true).subscribe(user => {
                    this.user = Object.assign({}, user);
                    this.prepForm();
                    this.loading = false;
                });
            } else {
                this.loading = false;
            }
        });
    }

    prepForm() {
        this.authType = !this.user.sso_id || this.user.sso_id.length === 0 ? "zm" : "sso";

        if (!this.isEdit) this.user.is_enabled = 1;

        // Set Title
        this.titleService.setTitle(
            this.translate.instant("USER") +
                " - " +
                (this.action ? this.titlecasePipe.transform(this.action) : "New") +
                " " +
                (this.user && this.user.name ? this.user.name : "")
        );
    }

    ngOnInit() {
        this.usersService.refreshUsers();

        this.usersSubscription = this.usersService.users.subscribe((users: User[]) => {
            if (this.isEdit) this.userNames = users.filter(u => u.id !== this.userId).map(u => u.name);
            else this.userNames = users.map(u => u.name);
        });

        this.prepForm();
    }

    ngOnDestroy() {
        this.usersSubscription.unsubscribe();
    }

    async onSubmit() {
        this.saving = true;

        const model = {
            name: this.user.name,
            password:
                this.authType === "zm" && (!this.isEdit || (this.user.password && this.user.password.length > 0))
                    ? this.user.password
                    : undefined,
            email: this.user.email,
            is_admin: this.user.is_admin,
            is_enabled: this.user.is_enabled,
            is_incident_manager: this.user.is_incident_manager,
            // is_zixi_admin: this.user.is_zixi_admin,
            // is_zixi_support: this.user.is_zixi_support,
            // is_zixi_support_write: this.user.is_zixi_support_write,
            must_reset: this.user.must_reset,
            sso_id: this.authType === "sso" ? "Pending" : undefined,
            group_ids: this.user.groups ? this.user.groups.map(g => g.id) : [],
            role_ids: this.user.roles ? this.user.roles.map(r => r.id) : []
        };

        if (this.isEdit) {
            const result = await this.usersService.updateUser(this.userId, model);
            if (result) {
                this.usersService.refreshUser(this.userId, true);
                this.router.navigate([Constants.urls.accountManagement.users, this.userId]);
            }
        } else {
            const result = await this.usersService.addUser(model);
            if (result) this.router.navigate([Constants.urls.accountManagement.users, result.id]);
        }

        this.saving = false;
    }

    cancel() {
        if (this.isEdit) this.router.navigate([Constants.urls.accountManagement.users, this.userId]);
        else this.router.navigate([Constants.urls.accountManagement.users]);
    }
}
